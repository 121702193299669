<template>
  <b-card title="All Item Inventory">
    <b-col cols="12" class="mb-2">
      <div class="filter-responsive">
        <div class="input-group">
          <div class="input-group-prepend">
            <b-dropdown style="margin: 0 !important;" id="dropdown-1" :text="selectedFilter" class="m-md-2">
              <b-dropdown-item @click="dropDownMenuClick" field="model">Model</b-dropdown-item>
              <b-dropdown-item @click="dropDownMenuClick" field="serial">Serial</b-dropdown-item>
              <b-dropdown-item @click="dropDownMenuClick" field="barcode">Barcode</b-dropdown-item>
              <b-dropdown-item @click="dropDownMenuClick" field="sapDo">DO</b-dropdown-item>
            </b-dropdown>
          </div>
          <b-form-input v-model="toFilter" placeholder="Filter.."/>
        </div>

        <b-button @click="doFilter" variant="primary">Filter</b-button>
        <b-button @click="clearFilter" variant="primary">Clear</b-button>
      </div>
    </b-col>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="table-responsive">
          <b-table
            :fields="fields"
            :items="items"
            :filter="search"
            :busy.sync="tableLoading"
            show-empty
          >
            <template #cell(customer)="row">
              {{ row.item.customer.type === 'Company' ? row.item.customer.companyName : `${row.item.customer.nameFirst} ${row.item.customer.nameLast}` }}
            </template>

            <template #cell(dealer)="row">
              {{ row.item.dealer !== undefined ? row.item.dealer.dealerName : '' }}
            </template>

            <template #cell(actions)="row">
              <b-button variant="primary" @click="toDetail(row.item)">Detail</b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import axios from '@/axios';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      inventory: [],
      fields: [
        { key: 'itemModel', label: 'Model', filterable: true, sortable: true },
        { key: 'customer', label: 'Customer Name', filterable: true, sortable: true },
        { key: 'barcode', label: 'Barcode', filterable: true, sortable: true },
        { key: 'serial', label: 'Serial', filterable: true, sortable: true },
        { key: 'sapDo', label: 'DO', filterable: true, sortable: true },
        { key: 'dealer', label: 'Dealer Name', filterable: true, sortable: true },
        { key: 'warrantyStatus', label: 'Warranty Status', filterable: true, sortable: true },
        { key: 'actions', label: 'Action' },
      ],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      model: undefined,
      barcode: undefined,
      serial: undefined,
      sapDo: undefined,
      selectedFilter: 'Model',
      selectedField: 'model',
      tableLoading: true,
    }
  },
  mounted() {
    this.getAllNonDrccItem({ 
      page: this.currentPage, 
      entry: this.perPage,
      serial: this.serial,
      barcode: this.barcode,
      sapDo: this.sapDo,
      model: this.model
    }).then((data) => this.processMetadata(data.metadata));
  },
  methods: {
    ...mapActions({
      getAllNonDrccItem: 'inventory/getAllNonOrderDrcc'
    }),
    onPageChange(page) {
      let filter = this.getFilter();

      this.getAllNonDrccItem({ 
        page: page, 
        entry: this.perPage,
        ...filter,
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    doFilter() {
      let filter = this.getFilter();

      this.getAllNonDrccItem({ 
        entry: this.perPage,
        ...filter,
      }).then((data) => this.processMetadata(data.metadata));
    },
    toDetail(item) {
      this.$router.push(`/admin/inventory/detail/${item._id}`);
    },
    dropDownMenuClick(event) {
      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute('field');
    },
    getFilter() {
      if (this.selectedFilter === null) {
        return {};
      }

      let filter = {};
      filter[this.selectedField] = this.toFilter;
      return filter;
    },
    clearFilter() {
      this.selectedField = null;
      this.toFilter = '';

      this.getAllNonDrccItem({
        entry: this.perPage,
      }).then((data) => this.processMetadata(data.metadata));
    }
  },
  computed: {
    ...mapGetters({
      items: 'inventory/NON_DRCC_ITEMS_CUSTOMER',
    })
  }
}
</script>

<style>
.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
    margin: 0 0 0 5px;
  }

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>